import React, {Fragment} from "react"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import { Link } from "gatsby"
import Banner from "../components/sections/banner"
import Fade from 'react-reveal/Fade';
import { graphql, useStaticQuery } from "gatsby"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    file(name: { eq: "scenario-1" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`)

  return <Layout>
    <SEO title="Personal finance forecasting software" />
    <Navigation />
    <Banner image={data.file.childImageSharp.fluid}>
      <SlidingBannerText/>
      <BannerForm
        action="https://betterplan.us18.list-manage.com/subscribe/post?u=ab08383b2c073e7fc56228fde&amp;id=570a5f4122"
        method="post"
      >
        <BannerInput placeholder="Your email" type="email" name="EMAIL" />
        <BannerButton type="submit" value="Subscribe" name="subscribe">
          join waitlist
        </BannerButton>
        <AvoidBotSignups />
      </BannerForm>
      <FormSubtitle>
        Already have a beta account?{" "}
        <FormSubtitleLink to="https://app.betterplan.com.au">
          Sign in
        </FormSubtitleLink>
      </FormSubtitle>
    </Banner>
    <Features />
    <Footer />
  </Layout>
}

export default IndexPage

const BannerText = ({className}) => {
  return <Fragment>
    <h1 className={className}>
      Make a <span>better plan</span> for your money
    </h1>
    <p  className={className}>
      We're building a better way to forecast your financial future. Sign up
      to get early access.
    </p>
</Fragment>
};

const SlidingBannerText = styled(BannerText)`

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

animation: 1s ease-out 0s 1 slideInFromLeft;`


export const BannerForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

export const FormSubtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
`

export const FormSubtitleLink = styled(Link)`
  color: ${props => props.theme.color.primary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.color.primary};
`

export const BannerInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const BannerButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.primary};
  box-shadow: rgba(26, 35, 126, 0.15) 0 5px 20px 5px;
  border-radius: 8px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: 0 0 0 0;
    background: ${props => props.theme.color.secondary};
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`


const AvoidBotSignups = () => {
  return <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
    <input type="text" name="b_ab08383b2c073e7fc56228fde_570a5f4122" tabindex="-1" value=""/>
  </div>
}
